import { routesMap } from '~/corporate/routes';
import { reduceInitialState, rootReducer } from '~/corporate/state/reducers';
import sharedSagas from '~/state/sagas';
import { SiteState } from '~/state/types';
import initializeStore, {
  ConfigureStoreOptions,
} from '~/store/initializeStore';

// Root store
export function configureStore(
  initialState: SiteState,
  { requestPath }: Pick<ConfigureStoreOptions, 'requestPath'>
) {
  return initializeStore({
    initialState,
    requestPath,
    reduceInitialState,
    rootSaga: sharedSagas,
    routesMap,
    rootReducers: rootReducer,
    hotReloadPath: 'corporate/state/reducers',
  });
}
