import { alerts } from '~/state/alerts/reducers';
import { apiConfig } from '~/state/apiConfig/reducers';
import { brandedBanner } from '~/state/brandedBanner/reducers';
import { catalogNavLinks } from '~/state/catalogNavLinks/reducers';
import { contentful } from '~/state/contentful/reducers';
import { currentUser } from '~/state/currentUser/reducers';
import { entities } from '~/state/entities/reducers';
import { features } from '~/state/features/reducers';
import { payment } from '~/state/payment/reducers';
import { proExclusive } from '~/state/proExclusive/reducers';

export const reduceInitialState = (initialProps: any = {}) =>
  initialProps.reduxData;

/**
 * To reduce the bundle size, only include reducers as necessary.
 */
export const rootReducer = {
  alerts,
  apiConfig,
  brandedBanner,
  catalogNavLinks,
  currentUser,
  features,
  payment,
  contentful,
  entities,
  proExclusive,
};
