import { theme } from '@codecademy/gamut-styles';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { createRootComponent } from '~/components/createRootComponent';
import { routesMeta } from '~/corporate/routes';
import { trackUserVisit } from '~/libs/eventTracking';
import { getRouteMetaForLocation } from '~/libs/location/routing';
import { createReduxBinder } from '~/libs/reduxBinder';
import { useSetDatadogContext } from '~/state/hooks/useSetDatadogContext';
import { selectLocationType } from '~/state/location/selectors';

import { configureStore } from './store/configureStore';

export interface CorporateProps {
  locationType: string;
  reduxData: unknown;
  corporatePage: unknown;
  statusCode: number;
}

const CorporateRouter: React.FC<React.PropsWithChildren<CorporateProps>> = ({
  corporatePage,
  reduxData,
  statusCode,
}) => {
  const locationType = useSelector(selectLocationType);
  const { scene: Scene, pageName } = getRouteMetaForLocation(
    routesMeta,
    locationType,
    statusCode
  );

  useEffect(() => {
    if (pageName) trackUserVisit({ page_name: pageName });
  }, [pageName]);

  useSetDatadogContext();
  return <Scene corporatePage={corporatePage} reduxData={reduxData} />;
};

export default createReduxBinder(
  createRootComponent(CorporateRouter, { theme }),
  configureStore
);
